<template>
    <div class="container">

        <loading-state
                v-if="loading"
                title="Loading today's jobs">
        </loading-state>

        <!-- My Repairs -->
        <template v-if="!isJobManager">
            <h4 class="mb-0">My repairs</h4>
            <div class="card-table mb-4" v-if="assetsForTechnician.length > 0">
                <div v-for="asset in assetsForTechnician" :key="asset.key" class="card-link-container">
                    <asset-list-item :asset="asset"></asset-list-item>
                </div>
            </div>
            <div class="card mb-4 mt-3" v-if="assetsForTechnician.length === 0">
                <empty-state
                        emptyIcon
                        icon="tools"
                        inline
                        small
                        title="No assets for you to repair">
                </empty-state>
            </div>
        </template>

        <!-- All Pending Repairs -->
        <h4 class="mb-0">All pending</h4>
        <div class="card-table" v-if="assetsNotForTechnician.length > 0">
            <div v-for="asset in assetsNotForTechnician" :key="asset.key" class="card-link-container">
                <asset-list-item :asset="asset"></asset-list-item>
            </div>
        </div>
        <div class="card mt-3" v-if="assetsNotForTechnician.length === 0">
            <empty-state
                    emptyIcon
                    icon="tools"
                    inline
                    small
                    title="No pending assets">
            </empty-state>
        </div>

    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex"

    export default {
        data() {
            return {
                loading: true
            }
        },

        computed: {
            ...mapGetters([
                "assetsPendingInHouseRepair",
                "isJobManager",
                "user"
            ]),

            /** Assets for the current technician */
            assetsForTechnician() {
                let assets = [];

                this.assetsPendingInHouseRepair.forEach(asset => {
                    if (!this.isJobManager && asset.technician_id.toString() === this.user.technician.id.toString()) {
                        assets.push(asset);
                    }
                });

                return assets;
            },

            /** All assets NOT for the current technician */
            assetsNotForTechnician() {
                let assets = [];

                this.assetsPendingInHouseRepair.forEach(asset => {
                    if (this.isJobManager || (!this.isJobManager && asset.technician_id.toString() !== this.user.technician.id.toString())) {
                        assets.push(asset);
                    }
                });

                return assets;
            }
        },

        mounted() {
            this.loadAssetsPendingInHouseRepair()
                .then(() => {
                    this.loading = false;
                });
        },

        methods: {
            ...mapActions([
                "loadAssetsPendingInHouseRepair"
            ])
        }
    }
</script>

<style scoped>

</style>